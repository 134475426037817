import React, {useEffect, useState, Suspense} from "react";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector.jsx";
import {useAuth} from "../../../contexts/AuthContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import axios from "../../../axios";

//const useAuth = React.lazy(()=>import('../../../contexts/AuthContext'));
const Modal = React.lazy(()=>import('../../components/Modal.jsx'));
//const LanguageSelector = React.lazy(()=>import('../../components/LanguageSelector.jsx'));
const DropDownMenu = React.lazy(()=>import('../../components/DropDownMenu.jsx'));


export default function MainMenu(props) {
    const [showLanguage, setShowLanguage] = useState(false);
    const {t, i18n} = useTranslation();
    const locale = i18n.language;
    const { user, stopImpersonation } = useAuth();
    const navigate = useNavigate();
    const [languagesActive, setLanguagesActive] = useState('active');
    const [currenciesActive, setCurrenciesActive] = useState('inactive');
    const [type, setType] = useState('language');
    const [currency, setCurrency] = useState('USD');
    const [menuString, setMenuString] = useState('');

    /*const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState('visible');*/
    const [top, setTop] = useState(0);
    const [position, setPosition] = useState('relative');
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [backUrl, setBackUrl] = useState('');
    const { params, setParams } = useParams();

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 10) || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, visible, handleScroll]);


    useEffect(() => {
        if (type === 'currency') { setLanguagesActive('inactive'); setCurrenciesActive('active');}
        else {setLanguagesActive('active'); setCurrenciesActive('inactive');}

    }, [type])

    //if (localStorage.getItem('currency_code')) setCurrency(localStorage.getItem('currency_code').toUpperCase())

    useEffect(() => {
        if (localStorage.getItem('currency_code')) {
            setCurrency(localStorage.getItem('currency_code').toUpperCase());
        } else {
            if (t('default_currency') != 'default_currency') setCurrency(t('default_currency').toUpperCase());
        }

    }, [currency])

    useEffect(() => {
        if (props.menuString) setMenuString(props.menuString);

        if (user){

                axios.get(`messages-check/${user.id}/user`).then((resp) => {
                    setUnreadMessages(resp.data);
                }).catch((error)=>{
                    if (error.response.status === 401) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('company_name');
                        window.location.href = window.location.href;
                    }
                })


        }




    },[props.menuString]);







    let title = <div className={'choose-globals'}>
        <div className={'choose-globals-element '+languagesActive} onClick={()=>{setType('language')}}>{t("Choose Language")}</div>
        <div className={'choose-globals-element '+currenciesActive} onClick={()=>{setType('currency')}}>{t("Choose Currency")}</div>
    </div>;

    //console.log(i18n.language);  onClick={() => setShowLogin(true)}

    function goBack(){
        if (!navigate(-1)){
            console.log('dddd');
            navigate('/');
        }
    }

    /*className="menu-background"*/

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };


    return (

    <div className="menu-background" >
        <Suspense fallback={<div>Loading...</div>}>
        <Modal title={title} onClose={() => setShowLanguage(false)} show={showLanguage} mode={'change-language-client'}>
            <LanguageSelector onClose={() => setShowLanguage(false)}
                              closeModal={() => {
                                  console.log(params);
                                  setShowLanguage(false);
                              }}
                              mode={'change-language-client'}
            type={type}
                              changeType={props.type}
            /> </Modal>
        </Suspense>

        <div className={`menu-container ${props.type} ${props.className}`}>
            <div className="top-line-menu">



                    {props.mode !== 'guest' &&
                    <>
                        <div className={"menu-button"} onClick={props.changeToggled}> <MenuIcon /></div>
                        <div className={'business-company-name'}>
                            {props.company}
                        </div>
                    </>}

                {props.mode === 'guest' &&
                    <div className="logo-type" style={{fontSize: "1.1rem"}}>
                        Unatam <span style={{color: "#ff1b2d"}}>Business</span>
                    </div>
                }





                <div className={"top-flag "+locale+'-flag search' }
                         onClick={() => {setType('language'); setShowLanguage(true)}}>
                    {localStorage.getItem('flag') ? (
                        <img alt={'flag'} src={process.env.REACT_APP_DOMAIN_NAME_API+'/storage/i/'+localStorage.getItem('flag')+'.png'} width={'100%'} height={'100%'}/>
                    ) : (
                        <img alt={'flag'} src={process.env.REACT_APP_DOMAIN_NAME_API+'/storage/i/'+locale+'.png'} width={'100%'} height={'100%'}/>
                    )}

                    </div>

                <Suspense fallback={<div>Loading...</div>}>
                    {<DropDownMenu messages={unreadMessages} mode={'guest'}
                       className={`${getCookie('impersonate_mode') ? ('glow'):('search')}`}
                    />}
                </Suspense>










            </div> {/*End  topLineMenu   */}
        </div> {/* End  menuContainer   */}
    </div>
    );

}


