import { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axios.jsx';


const AuthContent = createContext({
    user: null,
    setUser: () => {},
    csrfToken: () => {},
});

export const AuthProvider = ({ children }) => {
    const [user, _setUser] = useState(
        JSON.parse(localStorage.getItem('user')) || null
    );

    // set user to local storage
    const setUser = (user) => {
        if (user) {
          //  console.log(user);
            localStorage.setItem('user', JSON.stringify({avatar: user.avatar,
            name: user.name, family_name: user.family_name, email: user.email, phone: user.phone, companies: user.companies}));
        } else {
            localStorage.removeItem('user');
        }
        _setUser(user);
    };

    useEffect(() => {
        if (user) {
            axios.get('/user/info').then((resp) => {
                localStorage.setItem('user', JSON.stringify({avatar: resp.data.avatar,
                    name: resp.data.name, family_name: resp.data.family_name,
                    email: resp.data.email, phone: resp.data.phone, companies: resp.data.companies}));
               // console.log(resp.data);
                _setUser(resp.data);
                // setUserRoles(resp.data);
            }).catch(()=>{
                //console.log('unauthorized')
                });
        }

    },[]);

    // csrf token generation for guest methods
    const csrfToken = async () => {
        await axios.get(process.env.REACT_APP_DOMAIN_NAME_API+'/sanctum/csrf-cookie');
        return true;
    };

    const startImpersonation = async (userId) => {
        try {
            await axios.get(`/superadmin/impersonate/${userId}`);
            const userResponse = await axios.get('/user/info');
            setUser(userResponse.data);
            window.location = '/garage';
        } catch (error) {
            console.error('Error during impersonation:', error);
        }
    };

    const stopImpersonation = async () => {
        try {
            await axios.get('/superadmin/impersonate/leave');
            const userResponse = await axios.get('/user/info');
            setUser(userResponse.data);
        } catch (error) {
            console.error('Error stopping impersonation:', error);
        }
    };

    return (
        <AuthContent.Provider value={{ user, setUser, csrfToken, startImpersonation, stopImpersonation }}>
            {children}
        </AuthContent.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContent);
};
